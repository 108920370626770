<template>
  <div class="my-second replace ">
    <div class="replace-main">
      <Head :title="$t('wallet.exchange')" :show="true" :bg="true" />
      <div class="replace-top">
          <div>
            <p class="text">{{$t('add.enter_amount')}}</p>
          </div>
          <div class="replace-top-input">
              <van-field 
                v-model.number="bidtoken"
                :label="''"
                :placeholder="$t('add.please_amount')"
                :rules="[{ }]"
                @input="testIsNum"
              ></van-field>
              <p class="bid">Bid Token</p>
          </div>
          <p v-if="bidtoken!=''&&(parseInt(bidtoken)!=bidtoken)" class="error">{{$t('add.must_num')}}</p>
          <p v-if="bidtoken!=''&&(bidtoken>available)" class="error">{{$t("usdt.max_tips")}}</p>
          <p>{{$t('usdt.ablenum')}} : {{available | number_format}}</p>
      </div>
      <div class="content" >
        <ul class="content-list"  v-if="list.length>0">
          <li v-for="(l,i) in list" :key="i">
            <template v-if="(l.bt_amount-l.bt_value)!=0">
              <div class="fixed-icon1 fixed-text"  v-if="lang=='cn'">{{$t('wallet.giveaway')}}</div>
              <div class="fixed-icon2 fixed-text"  v-else>{{$t('wallet.giveaway')}}</div>
            </template>
            
            <van-checkbox v-model="l.checked" @click="changeTotal(l,i)" class="mycheck" checked-color="#DF561C"></van-checkbox>
            <div class="yhq">
              <p class="yhq-left">
                <span class="span3">{{parseInt(l.bt_value) | int_format}}</span>
                <span class="span4">USD</span>
              </p>
              <div class="yhq-right">
                <p class="p1">
                  <span style="margin-right:4px;">{{$t('wallet.exchange')}}</span>
                  <span>{{l.bt_value | int_format}} USD</span>
                </p>
                <div class="p2">
                  <span >{{$t('wallet.available')}}</span>
                  <p class="belt-img">
                    <img src="@/assets/wallet/wallet-logo.png" alt="">
                    <span>{{l.bt_value | int_format}}</span>
                  </p>
                  
                  <p class="belt-img" v-if="(l.bt_amount-l.bt_value)!=0">
                    <span class="add-icon">+</span>
                    <img src="@/assets/wallet/wallet-logo.png" alt="">
                    <span>{{(l.bt_amount-l.bt_value)  | int_format }}</span>
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="list.length==0" class="no-box">
          <img class="juan-no" :src="require('@/assets/images/xuqiu-no.png')"  alt="">
          <p class="no-text">{{$t('good.none_date')}}</p>
        </div>
      </div>
      <div class="footer"  >
        <p class="total">
          <span class="span1">{{$t('wallet.total')}} :</span>
          <span class="span4">  {{total | number_format }} USD</span>
        </p>
        <van-button class="pay" @click="chooseAction">{{$t('wallet.pay_now')}}</van-button>
      </div>
    </div>

     <!-- 支付成功 支付失败  -->
     <van-popup class="login-popup pay-one" v-model="show" position="bottom"   duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('wallet.pay')}}</p>
                <van-icon name="cross" class="pop-close" @click="show=false" />
            </div>
            <div class="pay-two" v-if="pay_success">
                <img :src="require('@/assets/images/pay-success.png')" alt="">
                <p>{{$t('wallet.pay')}}{{$t('wallet.success')}}</p>
            </div>
            <div class="pay-two" v-else >
                <img :src="require('@/assets/images/pay-fail.png')" alt="">
                <p>{{$t('wallet.pay')}}{{$t('wallet.fail')}}</p>
            </div>
            <van-button class="kown-btn" @click="show=false">{{$t('wallet.kown')}}</van-button>
        </div>
    </van-popup>

    <!--   -->
     <van-popup class="login-popup choose-one" v-model="chooseshow" position="bottom"   duration="0.2" >
        <div class="login-popup-box choose-box">
            <div class="pop-top choose-top">
                <p>{{$t('usdt.choose_type')}}</p>
                <van-icon name="cross" class="pop-close" @click="chooseshow=false" />
            </div>
            <div class="choose-list">
                <div class="choose-item" @click="chooseCurrentId(1011)">
                    <p>
                      <img :src="require('@/assets/imgs/usdt.png')" alt="">
                      <span>USDT</span>
                    </p>
                    <svg class="duigou" aria-hidden="true" v-if="current_id==1011" >
                      <use xlink:href="#iconxuanzhong"></use>
                    </svg>
                </div>
                 <div class="choose-item" @click="chooseCurrentId(1020)">
                    <p>
                      <img :src="require('@/assets/imgs/usdc.png')" alt="">
                      <span>USDC</span>
                    </p>
                    <svg class="duigou" aria-hidden="true"  v-if="current_id==1020" >
                      <use xlink:href="#iconxuanzhong"></use>
                    </svg>
                </div>
                 <div class="choose-item" @click="chooseCurrentId(1003)">
                    <p>
                      <img :src="require('@/assets/imgs/eth.png')" alt="">
                      <span>ETH</span>
                    </p>
                    <svg class="duigou" aria-hidden="true"  v-if="current_id==1003" >
                      <use xlink:href="#iconxuanzhong"></use>
                    </svg>
                </div>
                 <div class="choose-item" @click="chooseCurrentId(1021)">
                    <p>
                      <img :src="require('@/assets/imgs/dai.png')" alt="">
                      <span>DAI</span>
                    </p>
                    <svg class="duigou" aria-hidden="true"  v-if="current_id==1021">
                      <use xlink:href="#iconxuanzhong"></use>
                    </svg>
                </div>
            </div>
            <div class="choose-btn">
              <van-button class="paynowbtn" @click="secshow=true" >{{$t('wallet.pay_now')}}</van-button>
            </div>
            
        </div>
    </van-popup>

    <!-- 支付 输入二级密码   -->
     <van-popup class="login-popup pay-one" v-model="secshow" position="bottom"   duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('login.confirm')}}{{$t('wallet.pay')}}</p>
                <van-icon name="cross" class="pop-close" @click="secshow=false" />
            </div>
            <van-form label-width="140" class="pay-form" ref="payform" @submit="gotoexchange">
                <van-field
                v-model="secpass"
                :label="$t('login.second_pass')"
                :placeholder="$t('login.required_sec_pass')"
                :type="pt3"
                name="pin"
                :right-icon="pt3=='password'?'eye':'closed-eye'"
                @click-right-icon="changePassType3"
                :rules="[{ required: true, message:$t('login.ptian')}]"
                >

                </van-field>
            </van-form>
            <div class="last-btns">
                <van-button class="the-btn btn-one" @click="secshow=false">{{$t('login.cancel')}}</van-button>
                <van-button class="the-btn btn-two" @click="submitForm">{{$t('login.confirm')}}</van-button>
            </div>
        </div>
    </van-popup>
  </div>
</template> 
<script>
import {mapState,mapMutations} from "vuex"
import {randomString} from "@/common"
import {Notify} from "vant"
export default {
  name: "wallet-replace",
  data() {
    return {
      chooseshow:false,
      lang:localStorage.getItem("langData") || 'en',
      current:-1,
      checked: false,
      show:false,
      secshow:false,
      pt3:"password",
      secpass:'',
      obj:{},
      list:[],
      big:0,
      small:0,
      ids:'',
      pay_success:true,
      walletList:[],
      rate:0,
      current_id:"1011" , // USDT 
      bidtoken:'',
      available:0
    };
  },
  computed:{
      ...mapState(['taiprice']),
      total(){
        // 1bid token =  1usd  = 1/0.033389300000=30 泰铢
        return this.bidtoken ? (this.bidtoken*1).toFixed(6) :  (this.big*1).toFixed(6)
      }
  },
  watch:{
    secshow(v){
        this.pt3 = "password"
        this.secpass = "";
    }
  },
  methods:{
    testIsNum(){
      this.list = this.list.map(item=>{
        return {...item,checked:false}
      })
      this.ids = ''
      this.big = 0;
    },
    chooseAction(){
      if(this.ids  || this.bidtoken){
        this.chooseshow = true;
      }else{
        Notify({
            message:this.$t('usdt.choose_one'),
            color: '#ad0000',
            background: '#ffe1e1',
        });
      }
    },
    chooseCurrentId(current_id){
      this.current_id = current_id;
      this.rate = this.walletList.find(item=>item.current_id==current_id).rate;
    },
    changeTotal(v,index){
      this.bidtoken = "";
      this.ids = v.id;
      this.big = v.usdt_amount * 1;
      
      this.current = index;
      this.list = this.list.map(item=>{
        return {...item,checked:v.id==item.id}
      })
    },
    ...mapMutations(['changetaiprice']),
    changePassType3(){
          this.pt3 = this.pt3 == "password"?"text":"password"
    },
    submitForm(){
      this.$refs.payform.submit()
    },
    gotoexchange(value){
      // exchange_id ???  多个链接怎么给  
     var total = (this.total / this.rate * 1).toFixed(6)   //保留6位小数
     if(this.bidtoken){
      //  手动输入
      this.$ajax.cryptthexchangebt({
          bt_amount:this.bidtoken,
          pin:value.pin,
          pay_amount:total,
          current_id:this.current_id,
          pay_current_id:this.current_id,
          form_token:randomString()
        })
        .then(res=>{
            this.secshow = false;
            this.chooseshow = false;
            this.secpass = ""
            // this.show = true;
            if(res.code==200){
                Notify({ type: 'success', message: res.msg});
                // this.pay_success = true;
                this.$ajax.walletmy()
                .then(res=>{
                  if(res.code==200){
                    this.available = res.data.list.find(item=>item.current_id=='-1').usable_balance
                  }
                })
            }else{
              // this.pay_success = false;
            }
        })
     }else{
       this.$ajax.cryptthexchangebt({
          exchange_id:this.ids,
          pin:value.pin,
          pay_amount:total,
          current_id:this.current_id,
          pay_current_id:this.current_id,
          form_token:randomString()
        })
        .then(res=>{
            this.secshow = false;
            this.chooseshow = false;
            this.secpass = ""
            // this.show = true;
            if(res.code==200){
                Notify({ type: 'success', message: res.msg});
                // this.pay_success = true;
                this.$ajax.walletmy()
                .then(res=>{
                  if(res.code==200){
                    this.available = res.data.list.find(item=>item.current_id=='-1').usable_balance
                  }
                })
            }else{
              // this.pay_success = false;
            }
        })
     }
     
    }
  },
  mounted(){
    this.$ajax.cryptthexlist()
    .then(res=>{
        if(res.code==200){
          this.list = res.data.exchangeList
        }
    })

     this.$ajax.walletmy()
     .then(res=>{
       if(res.code==200){
         this.walletList = res.data.list
         this.rate = this.walletList.find(item=>item.current_id==this.current_id).rate;
         this.available = res.data.list.find(item=>item.current_id=='-1').usable_balance
       }
     })
  }
}
</script>